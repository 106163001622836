<template>
  <div v-if="loading" class="d-flex align-center justify-center fill-height">
    <v-progress-circular
      indeterminate
      color="ems-light-grey-blue"
    ></v-progress-circular>
  </div>
  <div v-else-if="!noData" class="fill-height">
    <div
      ref="main-echarts"
      :style="{ width: calWidth, height: calHeight }"
    ></div>
  </div>
  <div v-else class="d-flex align-center justify-center fill-height">
    <span class="ems-light-grey-blue--text font-weight-bold">
      {{ noDataText }}</span
    >
  </div>
</template>
<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
export default {
  name: 'Chart',
  props: {
    data: {
      type: Object,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    loadingOption: {
      type: Object,
      default: () => ({
        text: 'loading...',
        color: '#0092fd',
        textColor: '#082e62',
        maskColor: 'rgba(255, 255, 255, 0.8)'
        // zlevel: 0,
        // // 字体大小。从 `v4.8.0` 开始支持。
        // fontSize: 12,
        // // 是否显示旋转动画（spinner）。从 `v4.8.0` 开始支持。
        // showSpinner: true,
        // // 旋转动画（spinner）的半径。从 `v4.8.0` 开始支持。
        // spinnerRadius: 10,
        // // 旋转动画（spinner）的线宽。从 `v4.8.0` 开始支持。
        // lineWidth: 5
      })
    },
    noData: {
      type: Boolean,
      default: () => false
    },
    noDataText: {
      type: String,
      default: () => '無資料'
    },
    width: {
      type: String
    },
    height: {
      type: String
    }
  },
  watch: {
    async data() {
      await this.$nextTick()
      if (this.myChart) {
        this.myChart.clear()
      }
      if (this.data) {
        this.makeChart()
      }
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  computed: {
    ...mapGetters({
      chartColors: 'config/chartColors'
    }),
    chart() {
      return {
        color: this.chartColors,
        animation: false,
        grid: {
          top: 20,
          bottom: 50,
          left: 20,
          right: 20,
          containLabel: true
        },
        // scales: {
        //   yAxes: [{
        //     ticks: {
        //       beginAtZero: true
        //     }
        //   }]
        // },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100,
            realtime: false,
            labelFormatter: (valueStr) => {
              return (
                '資料密度範圍\n' +
                moment(valueStr).format('YYYY-MM-DD HH:mm:ss')
              )
            }
          }
        ],
        xAxis: {},
        yAxis: {},
        // xAxis: {
        //   type: 'category',
        //   data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        // },
        // yAxis: {
        //   type: 'value'
        // },
        // series: [{
        //   type: 'line',
        //   name: 'A',
        //   data: [820, 932, 901, 934, 1290, 1330, 1320]
        // },
        // {
        //   type: 'line',
        //   name: 'B',
        //   data: [20, 32, 91, 34, 1290, 30, 10]
        // }],
        ...this.data
      }
    },
    calWidth() {
      return this.width || '100%'
    },
    calHeight() {
      return this.height || '100%'
    }
  },
  methods: {
    async makeChart() {
      const el = this.$refs['main-echarts']
      if (!el) return
      this.myChart = echarts.init(el)
      await this.$nextTick()
      this.myChart.setOption(this.chart)
    },
    resize() {
      if (this.myChart) {
        this.myChart.resize()
      }
    },
    showLoading() {
      console.log('showLoading')
      // @showLoading="showLoading"
    },
    hideLoading() {
      console.log('showLoading')
    }
  },
  async mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.makeChart()
        window.addEventListener('resize', this.resize)
      }, 500)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
    if (this.myChart) {
      this.myChart.clear()
    }
    this.myChart = null
  }
}
</script>
<style lang="sass" scoped>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
// .echarts
//   width: 100%
//   height: 100%
  // min-height: 400px
</style>
