<template>
  <v-menu
    ref="menu"
    v-if="type"
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-if="type === 'daily'"
        v-model="dates"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        required
        :rules="[(v) => !!v || '此欄位為必填']"
        :solo="solo"
        :flat="flat"
        :dense="dense"
      >
        <template v-slot:label>
          請選擇日期<span class="error--text">*</span>
        </template>
      </v-text-field>
      <v-text-field
        v-else-if="type === 'monthly'"
        v-model="dates"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        required
        :rules="[(v) => !!v || '此欄位為必填']"
        :solo="solo"
        :flat="flat"
        :dense="dense"
      >
        <template v-slot:label>
          請選擇月份<span class="error--text">*</span>
        </template>
      </v-text-field>
      <v-text-field
        v-else-if="type === 'yearly'"
        v-model="dates"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        required
        :rules="[(v) => !!v || '此欄位為必填']"
        :solo="solo"
        :flat="flat"
        :dense="dense"
      >
        <template v-slot:label>
          請選擇年份<span class="error--text">*</span>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-show="type === 'daily'"
      ref="pickerDAILY"
      no-title
      show-week
      range
      scrollable
      color="primary"
      :max="dailyMax"
      :min="min"
      @input="pickDate($event)"
    >
    </v-date-picker>
    <v-date-picker
      v-show="type === 'monthly'"
      ref="pickerMONTHLY"
      type="month"
      no-title
      :max="monthlyMax"
      :min="min"
      @input="pickDate($event)"
    ></v-date-picker>
    <v-date-picker
      v-show="type === 'yearly'"
      ref="pickerYEARLY"
      no-title
      :max="yearlyMax"
      :min="min"
      @input="pickDate($event)"
      @click:year="saveYear($event)"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    value: {},
    type: {
      type: String,
      default: 'daily'
    },
    max: {
      type: String,
      default: () => moment().subtract(1, 'day').format('YYYY-MM-DD')
    },
    min: {
      type: String,
      default: () => moment().subtract(3, 'year').format('YYYY-MM-DD')
    },
    solo: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      dateMenu: false,
      datesRange: null
    }
  },
  computed: {
    dates: {
      get: function () {
        if (!this.datesRange || this.datesRange.length === 0) {
          return null
        }
        if (this.datesRange.length === 1) {
          return this.datesRange[0]
        }
        return this.datesRange[0] + '~' + this.datesRange[1]
      },
      set: function (newValue) {
        this.datesRange = newValue
      }
    },
    dailyMax() {
      return this.max
    },
    monthlyMax() {
      let outputMax = moment(this.max)
      if (
        moment(this.max).endOf('month').format('YYYY-MM-DD') !==
        moment(this.max).format('YYYY-MM-DD')
      ) {
        outputMax = outputMax.subtract(1, 'month')
      }
      outputMax = outputMax.endOf('month').format('YYYY-MM-DD')

      return outputMax
    },
    yearlyMax() {
      return moment(this.max)
        .subtract(1, 'year')
        .endOf('year')
        .format('YYYY-MM-DD')
    }
  },
  watch: {
    type() {
      this.dates = []
      this.setActivePicker()
    },
    dateMenu() {
      this.setActivePicker()
    },
    dates() {
      this.commit()
    },
    value(val) {
      this.datesRange = val
    }
  },
  created: function () {},
  mounted: function () {
    this.setActivePicker()
  },
  destroyed() {},
  methods: {
    pickDate(val) {
      console.log(this.type, val)
      switch (this.type) {
        case 'daily':
          this.dates = val
          break
        case 'monthly':
          this.dates = [
            moment(val, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
            moment(val, 'YYYY-MM').endOf('month').format('YYYY-MM-DD')
          ]
          break
        case 'yearly':
          this.dates = [
            moment(val).startOf('year').format('YYYY-MM-DD'),
            moment(val).endOf('year').format('YYYY-MM-DD')
          ]
          break
        default:
          break
      }
      console.log(this.type, val)
      this.dateMenu = false
    },
    saveYear(val) {
      this.dates = [
        moment(val, 'YYYY').startOf('year').format('YYYY-MM-DD'),
        moment(val, 'YYYY').endOf('year').format('YYYY-MM-DD')
      ]
      this.dateMenu = false
    },
    setActivePicker() {
      setTimeout(() => {
        if (!this.$refs.pickerYEARLY) {
          return
        }
        this.$nextTick(() => {
          this.$refs.pickerYEARLY.activePicker = 'YEAR'
        })
      }, 100)
    },
    commit() {
      this.$emit('input', this.datesRange)
    }
  }
}
</script>

<style></style>
