<template>
  <div>
    <div class="pa-3">
      <v-card
        class="mx-auto pa-3 ems-opacity-25-bg frosted-glass"
        elevation="0"
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row dense>
            <v-col cols="auto">
              <v-select
                dense
                v-model="query.type"
                :items="typeOptions"
                background-color="white"
                elevation="0"
                item-text="text"
                item-value="value"
                solo
                flat
                label="報表類型"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <reportDateRangeSelect
                v-model="selectDates"
                :type="query.type"
                solo
                flat
                dense
              />
            </v-col>
            <v-col cols="12" class="pa-0"> </v-col>
            <v-col cols="auto">
              <v-select
                dense
                v-model="query.meterId"
                :items="meters"
                background-color="white"
                elevation="0"
                item-text="name"
                item-value="id"
                solo
                flat
                :rules="[(v) => !!v || '此欄位為必填']"
              >
                <template v-slot:label>
                  請選擇電表<span class="error--text">*</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense class="mt-2 justify-sm-end">
            <v-col cols="auto">
              <v-btn outlined class="mr-4 px-8" @click="reset"> 重設 </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                :loading="loadingData"
                elevation="0"
                class="mr-4 px-8 accent--text"
                color="ems-aquamarine"
                @click="submit"
              >
                產生報表
              </v-btn>
            </v-col>
            <v-col cols="auto" v-permission="['SCH_ADMIN']">
              <downloadBtn @exportFile="exportFile" />
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-card
        class="mx-auto pa-3 mt-3 ems-opacity-50-bg frosted-glass"
        elevation="0"
        v-if="reportData"
      >
        <div class="pb-8" v-if="!loadingData">
          <div class="accent--text mr-4">
            最大需量: {{ reportData.maxDemand }} kW
          </div>
          <div class="accent--text mr-4">
            總用電量: {{ reportData.total }} kWh
          </div>
        </div>
        <div class="chart-wrap mt-3" v-if="!loadingData">
          <Echart
            :no-data="!reportData"
            noDataText="無資料"
            :data="chartDatasets"
          />
        </div>
        <div class="mt-4">
          <v-data-table
            :loading="loadingData"
            :headers="headers"
            :items="reportData.energy || []"
            item-class="accent--text"
            :items-per-page="10"
            loading-text="Loading..."
            no-data-text="查無資料"
            :footer-props="{
              'items-per-page-text': '每頁'
            }"
          ></v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Meter from '@/api/ems/Meter'
import Echart from '@/components/Echart'
import reportDateRangeSelect from '@/components/reportDateRangeSelect'
import makeChart from '@/vendor/chart'
import downloadBtn from './downloadBtn'

export default {
  components: {
    Echart,
    reportDateRangeSelect,
    downloadBtn
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectDates: [],
      typeOptions: [
        { value: 'daily', text: '日報' },
        { value: 'monthly', text: '月報' },
        { value: 'yearly', text: '年報' }
      ],
      query: {
        from: null,
        to: null,
        type: 'daily',
        page: 1,
        meterId: ''
      },
      exportQuery: null,
      valid: true,
      loadingData: false,
      loadingExport: false,
      loadingUpload: false,
      reportData: null,
      meters: []
    }
  },
  watch: {
    'query.type'() {
      this.closeMsg()
      this.$refs.form.resetValidation()
      this.query.from = ''
      this.query.to = ''
    },
    selectDates(val) {
      this.query.from = val[0]
      this.query.to = val[1] || val[0]
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData',
      navs: 'page/navs'
    }),
    id() {
      return this.pageData.id || this.tagId
    },
    headers() {
      // 時間
      const fields = [
        {
          value: 'timestamp',
          text: '時間',
          class: 'ems-bluegrey--text font-weignt-bold subtitle-1'
        },
        {
          value: 'value',
          text: '用電量',
          class: 'ems-bluegrey--text font-weignt-bold subtitle-1'
        }
      ]
      return fields
    },
    chartDatasets() {
      if (!this.reportData || !this.reportData.energy) {
        return null
      }
      return {
        ...makeChart.data(this.query.type),
        dataset: {
          dimensions: ['timestamp', 'value'],
          source: this.reportData.energy
        },
        series: [
          {
            name: '用電量',
            type: 'line'
          }
        ]
      }
    }
  },
  mounted() {
    this.getClassMeters()
  },
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show',
      closeMsg: 'snackbar/close'
    }),
    async getClassMeters() {
      try {
        const { data } = await Meter.getClassMeters(this.tagId)
        this.meters = data.sort(function (a, b) {
          return a.seq - b.seq
        })
        if (this.meters.length === 1) this.query.meterId = this.meters[0].id
      } catch (error) {
        console.error(error)
      }
    },
    reset() {
      this.closeMsg()
      this.$refs.form.resetValidation()
      this.query = {
        from: null,
        to: null,
        type: 'daily',
        page: 1
      }
      this.selectDates = []
    },
    async submit() {
      this.closeMsg()
      const validate = this.$refs.form.validate()
      if (!validate) return
      this.loadingData = true
      if (this.reportData) {
        this.reportData.energy = null
      }
      try {
        const { data } = await Meter.statistic(
          this.query.meterId,
          this.query.type,
          {
            isSchoolMeter: false,
            date: this.query.from
          }
        )
        this.reportData = data
      } catch (error) {
        console.error(error)
        this.reportData = null
        this.exportQuery = null
        const msg = error.response.data.message || '發生錯誤，請稍後再試'
        this.showMsg({
          message: `${error.response.status + ' ' || ''} ${msg}`,
          color: 'error',
          closable: true,
          time: 4000
        })
      } finally {
        this.loadingData = false
      }
    },
    async exportFile(type) {
      const validate = this.$refs.form.validate()
      if (!validate) return
      try {
        const response = await Meter.schoolStatisticExport(
          this.query.meterId,
          this.query.type,
          type,
          {
            isSchoolMeter: false,
            date: this.query.from
          }
        )
        this.downloadFile(response, type)
      } catch (error) {
        console.error(error)
      }
    },
    downloadFile(response, fileType) {
      if (fileType === 'csv') {
        const url = window.URL.createObjectURL(
          new Blob([(response.data ? '\ufeff' : '') + response.data], {
            type: 'text/csv;charset=utf-8;'
          })
        )
        const link = document.createElement('a')
        link.href = url
        let fileName = this.getResponseFilename(fileType)
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      } else if (fileType === 'json') {
        var dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(response.data))
        var downloadAnchorNode = document.createElement('a')
        downloadAnchorNode.setAttribute('href', dataStr)
        let fileName = this.getResponseFilename(fileType)
        downloadAnchorNode.setAttribute('download', fileName)
        document.body.appendChild(downloadAnchorNode) // required for firefox
        downloadAnchorNode.click()
        downloadAnchorNode.remove()
      }
    },
    getResponseFilename(fileType) {
      let meterName = this.meters.find(
        (meter) => meter.id === this.query.meterId
      ).name
      let reportType = this.typeOptions.find(
        (type) => type.value === this.query.type
      ).text
      let date = ''
      if (this.query.type === 'monthly')
        date = `${this.query.from.split('-')[0]}-${
          this.query.from.split('-')[1]
        }`
      else if (this.query.type === 'yearly')
        date = `${this.query.from.split('-')[0]}`
      else date = this.query.from
      return `${this.pageData.displayName}_${meterName}_${reportType}_${date}.${fileType}`
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-wrap {
  height: 390px;
  width: 100%;
}
*::v-deep {
  .v-data-table {
    background: transparent;
  }
}
</style>
