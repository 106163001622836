<template>
  <v-menu offset-y rounded="0" width="80" max-width="80">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        elevation="0"
        class="mr-4 px-8"
        v-bind="attrs"
        v-on="on"
        :loading="loading"
        :disabled="disabled"
      >
        匯出報表
      </v-btn>
    </template>
    <v-list color="accent" dark class="pa-0" width="80" max-width="80">
      <v-list-item @click="exportFile('csv')">
        <v-list-item-title>CSV</v-list-item-title>
      </v-list-item>
      <v-list-item @click="exportFile('json')">
        <v-list-item-title>JSON</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'
import fileDownload from 'js-file-download'
import Meter from '@/api/ems/Meter'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    value: {
      type: Object,
      require: true
    },
    classId: {},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      loading: false,
      item: this.value
    }
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show'
    }),
    async exportFile(type) {
      this.$emit('exportFile', type)
      // try {
      //   const resp = await Meter.classStatisticExport({
      //     ...this.value,
      //     id: this.classId,
      //     fileFormat: type
      //   })
      //   fileDownload(resp.data, this.getResponseFilename(resp.headers))
      // } catch (error) {
      //   this.showMsg({
      //     message: `${error.response.status + ' ' || ''} 發生錯誤，請稍後再試`,
      //     color: 'error',
      //     closable: true,
      //     time: 4000
      //   })
      // }
    },
    getResponseFilename(headers) {
      const disposition = headers['content-disposition']
      if (!disposition) {
        return null
      }

      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (!matches && !matches[1]) {
        return null
      }
      const filename = matches[1].replace(/['"]/g, '')
      console.log(decodeURI(filename))
      return decodeURI(filename)
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .v-list-item:hover {
    background-color: var(--v-primary-base);
  }
}
</style>
