const ChartYUnit = {
  DAILY_REPORT: 'kWh',
  WEEKLY_REPORT: 'kWh',
  MONTHLY_REPORT: 'kWh',
  YEARLY_REPORT: 'kWh',
  DEMAND: 'kW',
  demand: 'kW',
  voltage: 'V',
  voltageR: 'V',
  voltageS: 'V',
  voltageT: 'V',
  current: 'A',
  currentR: 'A',
  currentS: 'A',
  currentT: 'A',
  power: 'kW',
  POWER_FACTOR: 'PF',
  // 'HOUR_ENERGY': 'kWh',
  // 'DAY_ENERGY': 'kWh',
  // 'MONTH_ENERGY': 'kWh',
  hourEnergy: 'kWh',
  dayEnergy: 'kWh',
  monthEnergy: 'kWh',
  status: '',
  mode: '',
  TEMPERATURE: '（°C）',
  roomTemperature: '（°C）',
  temperature: '（°C）',
  humidity: '（%）', // 濕度,
  windSpeed: '（級）' // 風速
}
class Chart {
  grid(key) {
    switch (key) {
      case 'DAILY_REPORT':
      case 'WEEKLY_REPORT':
      case 'MONTHLY_REPORT':
      case 'YEARLY_REPORT':
      case 'demand':
      case 'power':
      case 'hourEnergy':
      case 'dayEnergy':
      case 'monthEnergy':
      case 'mode':
      case 'status':
      case 'TEMPERATURE':
      case 'roomTemperature':
      case 'temperature':
      case 'humidity':
      case 'windSpeed':
        return {
          top: 36,
          bottom: 60,
          left: 16,
          right: 50,
          containLabel: true
        }
      case 'voltage':
      case 'voltageR':
      case 'voltageT':
      case 'voltageS':
      case 'current':
      case 'currentR':
      case 'currentT':
      case 'currentS':
        return {
          // top: '5%',
          bottom: 60,
          left: 16,
          right: 50,
          containLabel: true
        }
      default:
        return {
          top: 20,
          bottom: 60,
          left: 16,
          right: 50,
          containLabel: true
        }
    }
  }
  legend(key) {
    switch (key) {
      case 'DAILY_REPORT':
      case 'WEEKLY_REPORT':
      case 'MONTHLY_REPORT':
      case 'YEARLY_REPORT':
      case 'voltage':
      case 'voltageR':
      case 'voltageT':
      case 'voltageS':
      case 'current':
      case 'currentR':
      case 'currentT':
      case 'currentS':
        return {
          right: 50,
          type: 'scroll'
        }
      default:
        return {
          right: 50,
          type: 'scroll'
        }
    }
  }
  tooltip(key) {
    switch (key) {
      case 'roomTemperature':
      case 'temperature':
      case 'humidity':
        return {
          trigger: 'axis',
          axisPointer: {
            animation: false
          }
          // formatter: '{a}{b}{c}{b0}: {c0}<br />{b1}: {c1}'
        }
      case 'windSpeed':
        return {
          trigger: 'axis',
          axisPointer: {
            animation: false
          },
          formatter: (params) => {
            const value = params[0].data.value
            return Number(value) >= 13 ? '超過12' : value
          }
        }
      case 'mode':
        return {
          trigger: 'axis',
          axisPointer: {
            animation: false
          },
          formatter: (params) => {
            const arr = ['送風', '冷房', '除溼', '自動', '暖氣']
            const item = params[0]
            return `<div class="caption">
              <div class="mb-1">${item.value.timestamp}</div>
              <div class="d-flex justify-space-between align-center"><span>${
                item.marker
              }${item.seriesName}</span> <b class="black--text pr-3">${
              arr[parseInt(item.value.value, 10)]
            }</b></div></div>`
          }
        }
      case 'status':
        return {
          trigger: 'axis',
          axisPointer: {
            animation: false
          },
          formatter: (params) => {
            const arr = ['OFF', 'ON']
            const item = params[0]
            return `<div class="caption">
              <div class="mb-1">${item.value.timestamp}</div>
              <div class="d-flex justify-space-between align-center"><span>${
                item.marker
              }${item.seriesName}</span> <b class="black--text pr-3">${
              arr[parseInt(item.value.value, 10)]
            }</b></div></div>`
          }
        }
      default:
        return {
          trigger: 'axis',
          axisPointer: {
            animation: false
          }
        }
    }
  }
  xAxis(key) {
    switch (key) {
      case 'MONTHLY_REPORT':
      case 'YEARLY_REPORT':
        return {
          type: 'time',
          axisLabel: {
            formatter: '{yyyy}-{MM}-{dd}'
          }
        }
      // case 'TEMPERATURE':
      // case 'roomTemperature':
      // case 'temperature':
      default:
        return {
          type: 'time',
          axisLabel: {
            formatter: '{yyyy}-{MM}-{dd}\n {HH}:{mm}'
          }
        }
    }
  }
  yAxis(key) {
    switch (key) {
      case 'DAILY_REPORT':
      case 'WEEKLY_REPORT':
      case 'MONTHLY_REPORT':
      case 'YEARLY_REPORT':
      case 'demand':
      case 'power':
      case 'hourEnergy':
      case 'dayEnergy':
      case 'monthEnergy':
      case 'voltage':
      case 'voltageR':
      case 'voltageT':
      case 'voltageS':
      case 'current':
      case 'currentR':
      case 'currentT':
      case 'currentS':
        return {
          type: 'value',
          name: ChartYUnit[key],
          axisLine: {
            show: true
          }
        }
      case 'TEMPERATURE':
      case 'roomTemperature':
      case 'temperature':
        return {
          type: 'value',
          minInterval: 1,
          name: ChartYUnit[key],
          axisLine: {
            show: true
          }
        }
      case 'humidity':
        return {
          type: 'value',
          minInterval: 0.1,
          name: ChartYUnit[key],
          axisLine: {
            show: true
          },
          axisLabel: {
            formatter: (value) => value * 100
          }
        }
      case 'windSpeed':
        return {
          type: 'value',
          max: 13,
          interval: 1,
          name: ChartYUnit[key],
          axisLine: {
            show: true
          },
          axisLabel: {
            formatter: (value) => {
              return Number(value) >= 13 ? '超過12' : value
            }
          }
        }
      case 'mode':
        return {
          type: 'value',
          minInterval: 1,
          data: [0, 1, 2, 3, 4],
          axisLine: {
            show: true
          },
          axisLabel: {
            formatter: (value) => {
              const arr = ['送風', '冷房', '除溼', '自動', '暖氣']
              return arr[value]
            }
          }
        }
      case 'status':
        return {
          type: 'value',
          minInterval: 1,
          axisLine: {
            show: true
          },
          axisLabel: {
            formatter: (value) => (value === 0 ? 'OFF' : 'ON')
          }
        }
      default:
        return {
          // type: 'time'
          type: 'value'
        }
    }
  }
  series(key) {
    switch (key) {
      case 'mode':
        return {
          name: '模式',
          type: 'line',
          step: 'middle'
        }
      case 'status':
        return {
          name: '狀態',
          type: 'line',
          step: 'middle'
        }
      case 'roomTemperature':
      case 'temperature':
        return {
          name: '溫度',
          type: 'line'
        }
      case 'humidity':
        return {
          name: '濕度',
          type: 'line'
        }
      case 'windSpeed':
        return {
          name: '風速',
          type: 'line'
        }
      default:
        return {
          type: 'line',
          large: true,
          largeThreshold: 500
        }
    }
  }
  data(type) {
    return {
      legend: this.legend(type),
      grid: this.grid(type),
      tooltip: this.tooltip(type),
      series: this.series(type),
      xAxis: this.xAxis(type),
      yAxis: this.yAxis(type)
    }
  }
}

const chart = new Chart()
export default chart
